import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AES } from "crypto-js";
import './LoginPage.css';
import TopBar from "./components/TopBar";
// import Footer from "./components/Footer";
import { Container, Grid, Form, Button, Segment, Input, Menu } from "semantic-ui-react";


const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [operatorEmail, setOperatorEmail] = useState('')
  const [operatorPassword, setOperatorPassword] = useState('')
  const navigate = useNavigate();
  const [activeItem, setActiveItem] = useState("adminLogin");

  const handleItemClick = (e, { name }) => setActiveItem(name)

  const handleSubmit = async (e) => {
    e.preventDefault();
    const encryptedPassword = AES.encrypt(password, process.env.REACT_APP_SECRET_KEY).toString();
    const user = { email, password: encryptedPassword };
    try {
      const response = await axios.post("https://wordwallrebuild.herokuapp.com/api/auth/login", user);
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("name", response.data.username);
      navigate("/ProductsPage", { state: { token: response.data.token, username: response.data.username } });
    } catch (error) {
      console.error(error);
    }
  };

  const handleOperatorSubmit = async (e) => {
    e.preventDefault();
    const encryptedPassword = AES.encrypt(operatorPassword, process.env.REACT_APP_SECRET_KEY).toString();
    const user = { email: operatorEmail, password: encryptedPassword };
    try {
      const response = await axios.post("https://wordwallrebuild.herokuapp.com/api/auth/login/operators", user);
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("name", response.data.username);
      // navigate("/MosaicSimplifiedEventListPage", { state: { token: response.data.token, username: response.data.username } });
      navigate("/ClientProductsPage", { state: { token: response.data.token, username: response.data.username } });

    } catch (error) {
      console.error(error);
    }
  };

  const handleUserLogin = () => {
    navigate("/ClientProductsPage");
  };


  return (
    <div>
      <TopBar />

      <Container>
        <Grid centered columns={1} verticalAlign="middle" style={{ height: "100vh" }}>
          <Grid.Column style={{ maxWidth: 450 }}>
            <Menu className="menu-container">
              <Menu.Item
                className="menu-item"
                name='adminLogin'
                active={activeItem === 'adminLogin'}
                onClick={handleItemClick}
              >
                Admin Panel
              </Menu.Item>

              <Menu.Item
                name='userLogin'
                className="menu-item"
                active={activeItem === 'userLogin'}
                onClick={handleItemClick}

              >
                Operator Panel
              </Menu.Item>
            </Menu>
            {activeItem === 'adminLogin' ?
              <div>
                <h1 style={{ textAlign: "center" }}>Admin Login</h1>

                <Form onSubmit={handleSubmit}>
                  <Form.Field>
                    <label>Email:</label>
                    <Input type="email" className="custom-username-input-box" value={email} onChange={(e) => setEmail(e.target.value)} required />
                  </Form.Field>
                  <Form.Field>
                    <label>Password:</label>
                    <Input type="password" className="custom-password-input-box" value={password} onChange={(e) => setPassword(e.target.value)} required />
                  </Form.Field>
                  <Form.Field>
                    <Button type="submit" className="custom-login-button" style={{ display: 'block', margin: '0 auto' }}>Submit</Button>
                  </Form.Field>
                </Form>
              </div>
              :
              // <div>
              //   <h1 style={{ textAlign: "center" }}>Live Links</h1>
              //   <Button onClick={handleUserLogin} className="custom-login-button" style={{ display: 'block', margin: '0 auto' }}>Go</Button>
              // </div>

              <div>
                <h1 style={{ textAlign: "center" }}>Operator Login</h1>

                <Form onSubmit={handleOperatorSubmit}>
                  <Form.Field>
                    <label>Email:</label>
                    <Input type="email" className="custom-username-input-box" value={operatorEmail} onChange={(e) => setOperatorEmail(e.target.value)} required />
                  </Form.Field>
                  <Form.Field>
                    <label>Password:</label>
                    <Input type="password" className="custom-password-input-box" value={operatorPassword} onChange={(e) => setOperatorPassword(e.target.value)} required />
                  </Form.Field>
                  <Form.Field>
                    <Button type="submit" className="custom-login-button" style={{ display: 'block', margin: '0 auto' }}>Submit</Button>
                  </Form.Field>
                </Form>
              </div>
            }

          </Grid.Column>
        </Grid>
      </Container>
    </div>
  );
};
export default LoginPage;
