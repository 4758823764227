import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { Container, Grid, Button, Image, Segment } from "semantic-ui-react";
import backgroundImage from "../../img/wordcloudImages/customershowbackground.png";
import logoImage from "../../img/wordcloudImages/Braze_Primary_logo_WHITE.png";

function getQueryParamValue(paramName) {
  const urlParams = new URLSearchParams(window.location.search);
  for (let [key, value] of urlParams.entries()) {
    if (key.toLowerCase() === paramName.toLowerCase()) {
      return value;
    }
  }
  return null; // return null if the parameter is not found
}


const LiveIntroPage1 = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const eventName = getQueryParamValue("eventName");
  const [device, setDevice] = useState("desktop"); // default to desktop

  const handleClick = (e) => {
    console.log('Button clicked or tapped');

    navigate("/WordCloud/LiveUserInputPage1", {
      state: { eventName: eventName ? eventName : location.state.eventName },
    });
  };


  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://wordwallrebuild.herokuapp.com/wordCloudDashboard/event-dashboard",
        {
          params: {
            eventName: eventName ? eventName : location.state.eventName,
          },
        }
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const setResponsiveness = () => {
      if (window.innerWidth < 768) {
        setDevice("phone");
      } else if (window.innerWidth < 992) {
        setDevice("tablet");
      } else {
        //actually the Ipad is using desktop view, but it's good
        setDevice("desktop");
      }
    };

    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  return (
    <>
      <style>
        {`
          @media only screen and (max-width:767px){
            .ui.container{
                margin-left:0!important;
                margin-right:0!important;
            }
          }
        `}
      </style>
      {device === "desktop" && (
        <Container
          fluid
          style={{
            backgroundImage: `url(${backgroundImage})`,
            // background: '#000000',
            backgroundSize: "100% auto",
            backgroundPosition: "center",
          }}
        >
          <Grid centered verticalAlign="middle" style={{ height: "100vh", margin: 0 }}>
            <Grid.Row>
              <Grid.Column textAlign="center" style={{ display: 'flex', alignItems: 'center' }}>
                <Segment basic style={{ display: 'contents' }}>
                  <Image style={{ width: '50vw' }} src={logoImage} alt="" />
                  <Button
                    size="massive"
                    primary
                    onClick={handleClick}
                    style={{
                      // marginTop: '-5vh',
                      margin: 0,
                      background: "rgba(0, 0, 0, 0)",
                      fontFamily: 'customershow_font',
                      fontSize: '60px',
                      fontWeight: '700'
                    }}
                  >
                    Tap to Start
                  </Button>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      )}
      {device === "phone" && (
        // <Container
        //   fluid
        // >
        //   <Grid
        //     centered verticalAlign="middle" style={{
        //       height: '10vh',
        //       background: '#BF6440'
        //     }}>
        //   </Grid>
        //   <Grid centered verticalAlign="middle" style={{
        //     height: "90vh",
        //     // backgroundImage: `url(${backgroundImage})`,
        //     background: '#000000',
        //     backgroundSize: "400%",
        //     backgroundPosition: "50% 30%",
        //     marginLeft: "0px !important",
        //     marginRight: "0px !important",
        //   }}>
        //     <Grid.Row>
        //       <Grid.Column textAlign="center" style={{ display: 'flex', alignItems: 'center' }}>
        //         <Segment basic style={{ display: 'contents', marginBottom: '30vh' }}>
        //           <Image style={{ width: '40vw', paddingBottom: '15vh' }} src={logoImage} alt="" />

        //         </Segment>
        //         <Segment basic style={{ display: 'contents' }}>
        //           <Button
        //             fluid
        //             primary
        //             onClick={handleClick}
        //             style={{
        //               marginTop: '0vh',
        //               background: "rgba(0, 0, 0, 0)",
        //               fontFamily: 'customershow_font',
        //               fontSize: '37px',
        //               fontWeight:'700',
        //               paddingBottom: '35vh'
        //             }}
        //           >
        //             Tap to Start.
        //           </Button>
        //         </Segment>
        //       </Grid.Column>
        //     </Grid.Row>
        //   </Grid>
        // </Container>
        <Container
          fluid
          style={{
            backgroundImage: `url(${backgroundImage})`,
            // background: '#000000',
            backgroundSize: "100% auto",
            backgroundPosition: "center",
          }}
        >
          <Grid centered verticalAlign="middle" style={{ height: "100vh", margin: 0 }}>
            <Grid.Row>
              <Grid.Column textAlign="center" style={{ display: 'flex', alignItems: 'center' }}>
                <Segment basic style={{ display: 'contents' }}>
                  <Image style={{ width: '50vw' }} src={logoImage} alt="" />
                  <Button
                    size="massive"
                    primary
                    onClick={handleClick}
                    style={{
                      // marginTop: '-5vh',
                      margin: 0,
                      background: "rgba(0, 0, 0, 0)",
                      fontFamily: 'customershow_font',
                      fontSize: '60px',
                      fontWeight: '700'
                    }}
                  >
                    Tap to Start
                  </Button>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      )}
      {device === "tablet" && (
        // <Container
        //   fluid
        //   style={{
        //     // backgroundImage: `url(${backgroundImage})`,
        //     background: '#000000',

        //     backgroundSize: "100% auto",
        //     backgroundPosition: "center",
        //     marginLeft: "0px !important",
        //     marginRight: "0px !important",
        //   }}
        // >
        //   <Grid centered verticalAlign="middle" style={{ height: "100vh", margin: 0 }}>
        //     <Grid.Row>
        //       <Grid.Column textAlign="center" style={{ display: 'flex', alignItems: 'center' }}>
        //         <Segment basic style={{ display: 'contents' }}>
        //           <Image style={{ width: '50vw' }} src={logoImage} alt="" />
        //           <Button
        //             size="large"
        //             primary
        //             onClick={handleClick}
        //             style={{
        //               fontFamily: 'customershow_font',
        //               // marginTop: '0vh',
        //               margin:0,
        //               background: "rgba(0, 0, 0, 0)",
        //               fontSize: '50px',
        //               fontWeight:'700'
        //             }}
        //           >
        //             Tap to Start
        //           </Button>
        //         </Segment>
        //       </Grid.Column>
        //     </Grid.Row>
        //   </Grid>
        // </Container>
        <Container
          fluid
          style={{
            backgroundImage: `url(${backgroundImage})`,
            // background: '#000000',
            backgroundSize: "100% auto",
            backgroundPosition: "center",
          }}
        >
          <Grid centered verticalAlign="middle" style={{ height: "100vh", margin: 0 }}>
            <Grid.Row>
              <Grid.Column textAlign="center" style={{ display: 'flex', alignItems: 'center' }}>
                <Segment basic style={{ display: 'contents' }}>
                  <Image style={{ width: '50vw' }} src={logoImage} alt="" />
                  <Button
                    size="massive"
                    primary
                    onClick={handleClick}
                    style={{
                      // marginTop: '-5vh',
                      margin: 0,
                      background: "rgba(0, 0, 0, 0)",
                      fontFamily: 'customershow_font',
                      fontSize: '60px',
                      fontWeight: '700'
                    }}
                  >
                    Tap to Start
                  </Button>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      )}
    </>
  );
};

export default LiveIntroPage1;
